$(function () {
    $('#slider_block').slick({
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    });
    $('body').on('click', function (e) {
        if ($('#category_list').hasClass('active') && $(e.target).closest('#category_list').length === 0 && $(e.target).closest('#drop_down_category').length === 0) {
            hideCategory();
        }
        let $lenguageList = $('#language_list');
        let $countryList = $('#country_list');
        if ($lenguageList.hasClass('active') && $(e.target).closest('#language_list').length === 0) {
            $lenguageList.removeClass('active');
        }
        if ($countryList.hasClass('active') && $(e.target).closest('#country_list').length === 0) {
            $countryList.removeClass('active');
        }
    });
    $('#category_list').on('click', function () {
        if ($(this).hasClass('active')) {
            hideCategory();
        } else {
            showCategory();
        }
    });
    $('#language_list').on('click', function () {
        $(this).toggleClass('active');
    });
    $('#country_list').on('click', function (e) {
        if (!$(this).hasClass('active')) {
            // Почему то округление по разному работает в js и в браузере и каждый раз на 0,01 ширина уменьшается.
            const addWidth = 0.01;
            $(this).parent().css('max-width', $(this).parent().width() + addWidth);
        }
        if ($(e.target).closest('.drop_down_cont').length === 0) {
            $(this).toggleClass('active');
        }
    });

    $('.change_item').on('click', function (e) {
        e.preventDefault();
        let href = $(this).attr('href');
        setItemMenuConditins(href);
    });

    if (location.hash) {
        let href = location.hash;
        setItemMenuConditins(href);
    }
});

function setItemMenuConditins(href) {
    $('.item_list_menu .item_menu').removeClass('active');
    $('.item_list_menu [href="' + href + '"').closest('.item_menu').addClass('active');
    $('.item_content').removeClass('active');
    $('[data-item="' + href + '"').addClass('active');
    location.hash = href;
}

function showCategory() {
    let $dropDownCategory = $('#drop_down_category');
    let height = $dropDownCategory.find('.wrap_drop_down')[0].offsetHeight;
    let top = $('.header .section_bottom').height();
    $dropDownCategory.height(height);
    $dropDownCategory.css('top', top + 10);
    $('#category_list').addClass('active');
    $dropDownCategory.addClass('active');
}

function hideCategory() {
    let $dropDownCategory = $('#drop_down_category');
    let height = 0;
    $dropDownCategory.height(height);
    $('#category_list').removeClass('active');
    $dropDownCategory.removeClass('active');
}
